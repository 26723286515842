<template>
  <div class="container">
    <h1>关于固废资源化数据库</h1>
    <p>“中国固废资源化技术数据库与技术绩效评价系统”依托国家重点研发计划“固废资源化技术多维绩效测评研究与集成应用”课题
      “固废资源化技术多源数据库开发及多维绩效评价方法研究（2018YFC1903601）”开发。</p>
    <p>
      本系统梳理了固废类型与来源、资源化产品安全性、固废组分迁移-转化-归趋、全生命周期环境影响等数据元及逻辑关系，完成了包括固废多元特征子库、固废资源化技术子库、资源化过程输入-输出清单子库的数据结构设计，并通过城市、年份、固废类型等字段建立多源数据的关联。</p>
    <p>
      本系统的数据来源包括20余项统计年鉴、280余项城市尺度固体废物污染环境防治信息公告、10余项工程技术手册、近万份科技文献和技术专利，对固废特征和技术数据进行汇集。创新性的采用物质流平衡核算、BPNN人工神经网络模型、消费品寿命周期模型（lifetime）、产排污系数算法等方法，贡献了城市精度下的废弃资源产量、磷石膏产量、生活垃圾组分、食品和塑料固废全生命周期流动等系统化、全面性的数据集。固废资源化技术数据库，覆盖了80%以上的固废类型和70%以上固废资源化技术类型，总数据量超100万条。</p>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
h1 {
  text-align: center;
}

p {
  text-indent: 1cm;
}
</style>